<template>
  <v-autocomplete
    class="select-user"
    :chips="!!value"
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    @input="onChange"
  ></v-autocomplete>
</template>
<script>
/**
 * ==================================================================================
 * Select Category
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'
import { find } from 'lodash'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select user',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'full_name',
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    role: {
      type: [String],
      default: null,
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    propertyId: {
      type: [String, Number],
      default: null,
    },

    allUser: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      users: [],
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.users && this.users.length ? this.users : []),
      ]

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  watch: {
    buildingId(newValue, oldValue) {
      this.fetch()
    },
    propertyId(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getUsersByFilter: 'user/getUsersByFilter',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
    }),

    async fetch() {
      if (this.fetching) return
      this.fetching = true

      try {
        const filter =
          this.buildingId || this.propertyId
            ? { propertyId: this.propertyId, buildingId: this.buildingId }
            : this.allUser
            ? {}
            : null

        if (!filter) return

        const data = await this.getUsersByFilter(filter)
        this.users = data

        if (this.preSelect && this.validItems.length) {
          this.onChange(this.validItems[0].id)
        }

        this.$emit('populated', this.users)
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
    },

    addUser(user) {
      const exist = find(this.users, { id: user.id })
      if (!exist) {
        this.users.push(user)
      }
    },

    updateUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.$set(this.users, index, user)
      }
    },

    removeUser(user) {
      const index = find(this.users, { id: user.id })
      if (index) {
        this.users.splice(index)
      }
    },
  },
}
</script>
