<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-icon left>mdi-wrench</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Maintenance Requests ({{ totalMaintenances }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        @click="dialogCreate = true"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Request
      </v-btn>

      <v-btn
        class="appbar__btn ml-5 font-weight-bold"
        height="40px"
        @click="exportList"
      >
        <v-icon class="mr-2">mdi-export-variant</v-icon>
        EXPORT
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="maintenance-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building, category and urgency"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="maintenances"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalMaintenances"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.user="{ item }">
          <div v-if="item.assignedName">{{ item.assignedName }}</div>
        </template>
        <template v-slot:item.property="{ item }">
          <div v-if="item?.user?.property">
            {{ item?.user?.property?.name }}
          </div>
        </template>
        <template v-slot:item.category="{ item }">
          <div v-if="item.categoryLabel">{{ item.categoryLabel }}</div>
        </template>
        <template v-slot:item.urgency="{ item }">
          <div v-if="item.urgencyLabel">{{ item.urgencyLabel }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogCreate" width="600px">
      <v-card>
        <v-card-title>
          <span class="font-weight-bold">New Maintenance Request</span>
        </v-card-title>
        <v-card-text class="maintenance-card-body">
          <v-container>
            <v-form
              ref="form"
              class="maintenance-form__form"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col cols="12">
                  <span class="red--text"><strong>* </strong></span
                  ><label class="text-field-label">Building</label>
                  <SelectBuilding
                    flat
                    solo
                    required
                    v-model="form.building_id"
                    :error-messages="form.$getError('building_id')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  />
                </v-col>
                <v-col cols="12">
                  <span class="red--text"><strong>* </strong></span
                  ><label class="text-field-label">User</label>
                  <SelectUser
                    flat
                    solo
                    required
                    v-model="form.user_id"
                    :error-messages="form.$getError('user_id')"
                    :building-id="form.building_id || buildingId"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  />
                </v-col>
                <v-col cols="12">
                  <span class="red--text"><strong>* </strong></span>
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    flat
                    solo
                    required
                    placeholder="Title"
                    v-model="form.title"
                    :error-messages="form.$getError('title')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <span class="red--text"><strong>* </strong></span
                  ><label class="text-field-label">Category</label>
                  <SelectCategory
                    flat
                    solo
                    required
                    v-model="form.category_id"
                    :error-messages="form.$getError('category_id')"
                    :building-id="form.building_id || buildingId"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  />
                </v-col>
                <v-col cols="12">
                  <span class="red--text"><strong>* </strong></span
                  ><label class="text-field-label">Urgency</label>
                  <SelectUrgency
                    flat
                    solo
                    required
                    v-model="form.urgency_id"
                    :error-messages="form.$getError('urgency_id')"
                    :building-id="form.building_id || buildingId"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  />
                </v-col>
                <v-col cols="12">
                  <label class="text-field-label">Description</label>
                  <v-textarea
                    flat
                    solo
                    required
                    hide-details="auto"
                    placeholder="Description"
                    v-model="form.description"
                    :error-messages="form.$getError('description')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12">
                <RequestFileUpload
                  ref="imagesUploader"
                  class="my-2"
                  :loading="form.$busy"
                  :error="form.$getError('images')"
                  :for-update="isUpdate"
                  @updated="form.$clearError('images')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-uppercase text-light-grey btn-action"
            color="tdarken-1 "
            text
            @click="cancelDialogUpdate()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="text-uppercase btn-action"
            color="blue darken-1 text-uppercase"
            text
            @click="submit()"
            name="submitFormDocument"
            :loading="form.$busy"
            :disabled="!hasCreatePermission"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Status from '@/components/common/Status'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SelectBuilding from '@/components/fields/SelectBuilding.vue'
import RequestFileUpload from '@/views/Home/Maintenance/Requests/components/RequestFileUpload'
import SelectCategory from '@/components/fields/SelectCategory.vue'
import SelectUrgency from '@/components/fields/SelectUrgency.vue'
import Form from '@/utils/form'
import SelectUser from '@/components/fields/SelectUser.vue'

export default {
  name: 'MaintenancesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    SelectUser,
    SelectUrgency,
    SelectCategory,
    RequestFileUpload,
    SelectBuilding,
    Status,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      tabItems: [
        { tab: 'Pending', index: 0 },
        { tab: 'In Progress', index: 1 },
        { tab: 'Finished', index: 2 },
        { tab: 'Cancelled', index: 3 },
        { tab: 'All', index: 4 },
      ],
      defaultStatus: 0,
      defaultFilter: 'created_at',
      sortOrder: false,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      selected: [],
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '18%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Assigned',
          value: 'user',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Urgency',
          value: 'urgency',
          width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'center',
          width: '15%',
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
        {
          text: 'Last Updated',
          value: 'updated_at',
          width: '13%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      form: new Form({
        building_id: '',
        category_id: '',
        urgency_id: '',
        user_id: '',
        title: '',
        description: '',
        images: [],
      }),
      pageTables: 0,
      dialogCreate: false,
      itemsPerPageTables: 0,
    }
  },

  created() {
    this.fetchInitParams()
    this.clearMaintenances()
    this.fetch(this.options)
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      maintenances: (state) => state.maintenance.list,
      listMeta: (state) => state.maintenance.listMeta,
      totalMaintenances: (state) => state.maintenance.totalMaintenances,
      params: (state) => state.maintenance.params,
      scrollPosition: (state) => state.maintenance.scrollPosition,
    }),

    ...mapGetters({
      buildingId: 'filter/buildingId',
    }),

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.MAINTENANCES_CREATE],
        this.permissions
      )
    },
    isFileTooLarge() {
      if (this.form.file) {
        return this.form.file.size > 4 * 1024 * 1024
      }
      return false
    },
    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
    isUpdate() {
      return false
    },
  },

  methods: {
    ...mapActions({
      getMaintenances: 'maintenance/getMaintenances',
      exportToExcel: 'maintenance/exportToExcel',
      createMaintenance: 'maintenance/createRequest',
    }),

    ...mapMutations({
      clearMaintenances: 'maintenance/clearMaintenanceList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'maintenance/setParams',
      setScrollPosition: 'maintenance/setScrollPosition',
    }),

    filterList(filterValue) {
      this.clearMaintenances()
      this.fetch(this.options, filterValue)
    },

    activeAction() {
      this.clearMaintenances()
      this.fetch(this.options)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.options, this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearMaintenances()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalTags
              : options.itemsPerPage
            : 25,
          sort: options?.sortBy[0]
            ? options?.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
        status: this.defaultStatus,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.setParams(params)

      this.loading = true
      await this.getMaintenances(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      let params = {
        page: this.options?.page || 1,
        itemsPerPage: this.options?.itemsPerPage
          ? this.options?.itemsPerPage === -1
            ? this.totalMaintenances
            : this.options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: this.options?.sortBy[0]
          ? this.options.sortDesc[0]
            ? '-' + this.options.sortBy[0]
            : this.options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      await this.exportToExcel(params)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'requests_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
    validate() {
      this.form.$clearErrors()

      return !this.form.$hasErrors()
    },
    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.createMaintenance(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Maintenance details successfully updated!')
          this.dialogCreate = false
          this.fetch(this.options)
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },
    cancelDialogUpdate() {
      this.dialogCreate = false
      this.form = new Form({
        building_id: '',
        category_id: '',
        urgency_id: '',
        user_id: '',
        title: '',
        description: '',
      })
    },
    getFormData() {
      let form = this.form.$data()

      const formData = new FormData()
      for (var field in form) {
        if (Array.isArray(form[field])) {
          form[field].forEach((value, index) => {
            formData.append(`${field}[]`, value)
          })
        } else {
          formData.append(field, form[field])
        }
      }

      this.$refs.imagesUploader.getImages(formData)

      return formData
    },
    editItem(item) {
      this.$router.push({
        name: 'maintenance.request.details',
        params: { id: item.id },
      })
    },

    fetchInitParams() {
      this.defaultStatus = this.params.status

      this.options = this.params.options

      this.filter.search = this.params.search
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.selected = []
      this.activeAction()
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return

      const scrollPosition = this.scrollPosition
      this.setScrollPosition(0)

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}

.btn-action {
  height: 35px;
  width: 112px;
  margin: 24px 0px;
}
</style>
